import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EnvironmentNames } from './_core/contants/environments';
import Urls from './_core/contants/urls';
import { authGuard } from './_core/guards/auth.guard';
import { noAuthGuard } from './_core/guards/no-auth.guard';
import { PageNotFoundComponent } from './_shared/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: Urls.APP, pathMatch: 'full' },
  { path: `${Urls.PAGES}/${Urls.USER}`, redirectTo: Urls.AUTH },
  { path: Urls.PAGES, redirectTo: Urls.APP },
  {
    path: Urls.APP,
    canLoad: [authGuard],
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: Urls.AUTH,
    canLoad: [noAuthGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: Urls.PUBLIC,
    canLoad: [noAuthGuard],
    data: { environments: [EnvironmentNames.SWISS, EnvironmentNames.IGF, EnvironmentNames.CNCF, EnvironmentNames.GLF] },
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
